/*
THEME: Promodise - Startup Business Bootstrap Template
VERSION: 1.0.0
AUTHOR: Themefisher

HOMEPAGE: https://themefisher.com/products/promodise-startup-business-template/
DEMO: https://demo.themefisher.com/promodise/
GITHUB: https://github.com/themefisher/Promodise-Startup-Business-Bootstrap-Template

WEBSITE: https://themefisher.com
TWITTER: https://twitter.com/themefisher
FACEBOOK: https://www.facebook.com/themefisher
*/

@import 'variables.scss';

@import 'mixins.scss';

@import 'media-query.scss';

@import 'typography.scss';

@import 'common.scss';

@import 'templates/header.scss';

@import 'templates/navigation.scss';

@import 'templates/slider.scss';

@import 'templates/backgrounds.scss';
@import 'templates/intro.scss';
@import 'templates/about.scss';
@import 'templates/counter.scss';
@import 'templates/service.scss';
@import 'templates/pricing.scss';
@import 'templates/testimonial.scss';

@import 'templates/contact.scss';

@import 'templates/blog.scss';

@import 'templates/blog-sidebar.scss';

@import 'templates/footer.scss';
@import 'responsive.scss';




