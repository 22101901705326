/*------------------------------------
  MENU NAVIGATION AREA
----------------------------------*/
.site-navigation,
.trans-navigation {
  padding: 20px 0px;
}

.site-navigation .navbar-nav a,
.trans-navigation .navbar-nav a {
  text-transform: uppercase;
}

.site-navigation .navbar-nav a.active,
.trans-navigation .navbar-nav a.active {
  color: red;
}

.header-white {
  position: fixed;
  top: 0px;
  background: #111;
  z-index: 9999;
  width: 100%;
  left: 0;
  -webkit-transition: all .3s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  color: $black !important;
}

.navbar-expand-lg.trans-navigation .navbar-nav .nav-link,
.navbar-expand-lg.site-navigation .navbar-nav .nav-link {
  padding: 0px 24px;
}

.navbar-expand-lg.trans-navigation .navbar-nav .nav-link:last-child,
.navbar-expand-lg.site-navigation .navbar-nav .nav-link:last-child {
  padding-right: 0px;
}

.site-navigation,
.trans-navigation {
  -webkit-transition: all .3s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.navbar-brand {
  padding-top: 0px;
  margin-right: 60px;
  font-weight: 700;
  text-transform: capitalize;
  color: $light;
}

.navbar-brand span {
  font-weight: 400;
}

.navbar-expand-lg.trans-navigation .navbar-nav .nav-link {
  color: $light;
}

.trans-navigation {
  background: transparent;
}

.trans-navigation.header-white {
  background: #111;
}

.trans-navigation.header-white .navbar-nav .nav-link {
  color: $light;
}

.trans-navigation.header-white .navbar-nav a.active {
  color: $primary-color;

}

.navbar-brand img {
  max-width: 100%;
}

.trans-navigation.header-white .btn-white {
  background: $primary-color;
  color: $light;
}

/* ===============================
 DROPDOWN MENU
================================*/
.navbar-expand-lg.trans-navigation .navbar-nav .nav-link.dropdown-toggle {
  padding-right: 0px;
}

.dropdown-toggle i {
  font-size: 10px;
  vertical-align: 2px;
}

.dropdown-menu {
  padding: 0px;
  border: 0;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);

  @include desktop {
    text-align: center;
    float: left !important;
    width: 100%;
    margin: 0;
  }

  li:first-child {
    margin-top: 5px
  }

  li:last-child {
    margin-bottom: 5px
  }
}

.dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-menu,
.dropright .dropdown-menu {
  margin: 0;
}

.dropleft .dropdown-toggle::before,
.dropright .dropdown-toggle::after {
  font-weight: bold;
  font-family: 'Font Awesome 5 Free';
  border: 0;
  font-size: 10px;
  vertical-align: 1px;
}

.dropleft .dropdown-toggle::before {
  content: "\f053";
  margin-right: 5px;
}

.dropright .dropdown-toggle::after {
  content: "\f054";
  margin-left: 5px;
}

.dropdown-item {
  padding: .6rem 1.5rem .35rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.dropdown-submenu.active>.dropdown-toggle,
.dropdown-submenu:hover>.dropdown-item,
.dropdown-item.active,
.dropdown-item:hover {
  background: rgba($primary-color, .1);
  color: $primary-color;
}
button:focus {
  outline: 0;
}

ul.dropdown-menu li {
  padding-left: 0px !important;
}

@media (min-width:992px) {
  .dropdown-menu {
    transition: all .2s ease-in, visibility 0s linear .2s, transform .2s linear;
    display: block;
    visibility: hidden;
    opacity: 0;
    min-width: 200px;
    transform: translateY(10px);

    li:first-child {
      margin-top: 5px
    }

    li:last-child {
      margin-bottom: 5px
    }
  }

  .dropleft .dropdown-menu,
  .dropright .dropdown-menu {
    margin-top: -5px;
  }

  .dropdown:hover>.dropdown-menu {
    visibility: visible;
    transition: all .3s ease 0s;
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar-collapse {
  @include desktop {
    text-align: center;
  }

  &.show {
    overflow-y: auto;
    max-height: calc(100vh - 100px);
  }
}